/**
 * A button component that can be used to trigger an authentication request.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onAuthRequest - A function to be called when the button is clicked.
 * @param {string} props.identifier - The identifier of the authentication provider to use (e.g. "google").
 * @returns {JSX.Element} The rendered button component.
 */

"use client";
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { AUTH_IDENTIFIER } from "constants/auth";

const getIconURL = (identifier: string) => {
  switch (identifier) {
    case AUTH_IDENTIFIER.GOOGLE:
      return "/static/images/common/icon-sso-google.svg";
    case AUTH_IDENTIFIER.APPLE:
      return "/static/images/common/apple-logo-sso.svg";
    case AUTH_IDENTIFIER.GITHUB:
      return "/static/images/common/github-logo-sso.svg";
    case AUTH_IDENTIFIER.X:
      return "/static/images/common/x-logo-sso.svg";
    case AUTH_IDENTIFIER.MICROSOFT:
      return "/static/images/common/microsoft-logo-sso.svg";
    case AUTH_IDENTIFIER.FACEBOOK:
      return "/static/images/common/facebook-logo-sso.svg";
    case AUTH_IDENTIFIER.YAHOO:
      return "/static/images/common/yahoo-logo-sso.svg";
    default:
      return "";
  }
};
function AuthButton({
  onAuthRequest,
  identifier,
}: {
  onAuthRequest: Function;
  identifier: string;
}) {
  const handleClick = () => {
    if (onAuthRequest) {
      onAuthRequest();
    }
  };

  const iconURL = getIconURL(identifier);

  return (
    <>
      <button
        type="button"
        data-tooltip-target={`tooltip-auth-button-${identifier}`}
        data-tooltip-placement="top"
        data-tooltip-style="light"
        aria-label={`Continue with ${identifier} account`}
        className={classNames(
          "inline-block mt-3 h-12 clear-both cursor-pointer leading-12 text-gray-700 ml-1 mr-1 text-left pl-[52px] rounded-md ring-1 ring-gray-200 shadow-md",
          "bg-white bg-no-repeat bg-left-top bg-auto",
          "bg-white bg-no-repeat bg-center bg-contain"
        )}
        style={{
          backgroundImage: `url(${iconURL})`,
          backgroundPosition: "12px 12px",
          backgroundSize: "26px auto",
        }}
        onClick={handleClick}
      ></button>
      <div
        id={`tooltip-auth-button-${identifier}`}
        role="tooltip"
        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip"
      >
        {`Continue with ${identifier} account`}
        <div className="tooltip-arrow" data-popper-arrow></div>
      </div>
    </>
  );
}

AuthButton.propTypes = {
  onAuthRequest: PropTypes.func.isRequired,
};

export default AuthButton;
