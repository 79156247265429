"use client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { REGEX } from "constants/auth";
import InputError from "../../common/misc/InputError";
import Spinner from "components/common/misc/Spinner";
import { showErrorAlert } from "components/common/alert";
import { getMessageFromErrorCode, signup } from "../utils";
import { useRouter, useSearchParams } from "next/navigation";

function EmailSignup({ trans }: { trans: any }) {
  const [isLoading, setIsLoading] = useState(false);
  const searchParams: any = useSearchParams();
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const handleEmailSignup = async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    try {
      await signup(email, password, name);
      router.replace(`${searchParams.get("continueUrl") ?? "/dashboard"}`);
    } catch (error: any) {
      showErrorAlert({
        title: trans.common.error.authErrorTitle,
        description: getMessageFromErrorCode(error.code),
        secondaryButtonText: trans.common.close,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit: any = (data: {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "custom",
        message: trans.signup.confirmPasswordNotMatch,
      });
      return;
    }
    handleEmailSignup({ ...data });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-4 space-y-6"
        method="POST"
        noValidate
      >
        {/* Grid */}
        <div className="grid grid-cols-2 gap-4">
          {/* Input Group */}
          <div>
            {/* Floating Input */}
            <div className="relative">
              <input
                type="text"
                id="hs-hero-signup-form-floating-input-full-name"
                {...register("name", {
                  required: trans.common.nameRequire,
                  maxLength: 50,
                  pattern: {
                    value: REGEX.NAME,
                    message: "Invalid name.",
                  },
                })}
                className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                                    focus:pt-6
                                    focus:pb-2
                                    [&:not(:placeholder-shown)]:pt-6
                                    [&:not(:placeholder-shown)]:pb-2
                                    autofill:pt-6
                                    autofill:pb-2"
                placeholder="John"
              />
              <label
                htmlFor="hs-hero-signup-form-floating-input-full-name"
                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                    peer-focus:text-xs
                                    peer-focus:-translate-y-1.5
                                    peer-focus:text-gray-500
                                    peer-[:not(:placeholder-shown)]:text-xs
                                    peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                    peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                {trans.signup.nameLabel}
              </label>
              {errors.name && (
                <InputError message={`${errors.name?.message}`} />
              )}
            </div>
            {/* End Floating Input */}
          </div>
          {/* End Input Group */}
          {/* Input Group */}
          <div>
            {/* Floating Input */}
            <div className="relative">
              <input
                type="email"
                id="hs-hero-signup-form-floating-input-email"
                {...register("email", {
                  required: trans.common.emailRequire,
                  maxLength: 128,
                  pattern: {
                    value: REGEX.EMAIL,
                    message: trans.common.invalidEmail,
                  },
                })}
                className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                                    focus:pt-6
                                    focus:pb-2
                                    [&:not(:placeholder-shown)]:pt-6
                                    [&:not(:placeholder-shown)]:pb-2
                                    autofill:pt-6
                                    autofill:pb-2"
                placeholder="you@email.com"
              />
              <label
                htmlFor="hs-hero-signup-form-floating-input-email"
                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                    peer-focus:text-xs
                                    peer-focus:-translate-y-1.5
                                    peer-focus:text-gray-500
                                    peer-[:not(:placeholder-shown)]:text-xs
                                    peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                    peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                {trans.signup.emailLabel}
              </label>
              {errors.email && (
                <InputError message={`${errors.email?.message}`} />
              )}
            </div>
            {/* End Floating Input */}
          </div>
          {/* End Input Group */}

          {/* Input Group */}
          <div className="relative">
            {/* Floating Input */}
            <div className="relative">
              <input
                type="password"
                id="hs-hero-signup-form-floating-input-new-password"
                {...register("password", {
                  required: trans.common.passwordRequire,
                  maxLength: 128,
                  pattern: {
                    value: REGEX.PASSWORD,
                    message: trans.common.strongPasswordMessage,
                  },
                })}
                className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                                    focus:pt-6
                                    focus:pb-2
                                    [&:not(:placeholder-shown)]:pt-6
                                    [&:not(:placeholder-shown)]:pb-2
                                    autofill:pt-6
                                    autofill:pb-2"
                placeholder="********"
              />
              <label
                htmlFor="hs-hero-signup-form-floating-input-new-password"
                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                    peer-focus:text-xs
                                    peer-focus:-translate-y-1.5
                                    peer-focus:text-gray-500
                                    peer-[:not(:placeholder-shown)]:text-xs
                                    peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                    peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                {trans.signup.passwordLabel}
              </label>
              {errors.password && (
                <InputError message={`${errors.password?.message}`} />
              )}
            </div>
            {/* End Floating Input */}
          </div>
          {/* End Input Group */}
          {/* Input Group */}
          <div>
            {/* Floating Input */}
            <div className="relative">
              <input
                type="password"
                id="hs-hero-signup-form-floating-input-current-password"
                {...register("confirmPassword", {
                  required: trans.common.cnfPasswordRequire,
                  maxLength: 128,
                })}
                className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                                    focus:pt-6
                                    focus:pb-2
                                    [&:not(:placeholder-shown)]:pt-6
                                    [&:not(:placeholder-shown)]:pb-2
                                    autofill:pt-6
                                    autofill:pb-2"
                placeholder="********"
              />
              <label
                htmlFor="hs-hero-signup-form-floating-input-current-password"
                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                    peer-focus:text-xs
                                    peer-focus:-translate-y-1.5
                                    peer-focus:text-gray-500
                                    peer-[:not(:placeholder-shown)]:text-xs
                                    peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                    peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                {trans.signup.confirmPasswordLabel}
              </label>
              {errors.confirmPassword && (
                <InputError message={`${errors.confirmPassword?.message}`} />
              )}
            </div>
            {/* End Floating Input */}
          </div>
          {/* End Input Group */}
        </div>
        {/* End Grid */}
        {/* Checkbox */}
        <div className="mt-5 flex items-center">
          <div className="flex">
            <input
              id="remember-mr"
              type="checkbox"
              {...register("privacyAccepted", {
                required: trans.signup.acceptPolicyMessage,
              })}
              className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
            />
          </div>
          <div className="ml-3">
            <label htmlFor="remember-mr" className="text-sm dark:text-white">
              {trans.signup.iAccept + " "}
              <a
                className="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/policies/terms"
              >
                {trans.signup.tncLinkText + " "}
              </a>
              {trans.common.and + " "}
              <a
                className="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="/policies/privacy"
              >
                {trans.signup.privacyLinkText}
              </a>
            </label>
            {errors.privacyAccepted && (
              <InputError message={`${errors.privacyAccepted?.message}`} />
            )}
          </div>
        </div>

        {/* End Checkbox */}
        <div className="mt-5">
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              {isLoading ? <Spinner /> : trans.signup.getStarted}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default EmailSignup;
