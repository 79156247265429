"use client";
import AuthButton from "./AuthButton";
import { AUTH_IDENTIFIER } from "constants/auth";
import { useRouter, useSearchParams } from "next/navigation";
import {
  signInWithFacebook,
  signInWithGithub,
  signInWithGoogle,
  signInWithTwitter,
  signInWithYahoo,
} from "../utils";
import { showErrorAlert } from "components/common/alert";

const Social = ({ trans }: { trans: any }) => {
  const searchParams: any = useSearchParams();
  const router = useRouter();

  const showErrorMessage = (code: string | undefined) => {
    let description = trans.common.error.errorOccurred;
    if (code === "auth/account-exists-with-different-credential") {
      description = trans.common.error.accountExist;
    }
    showErrorAlert({
      title: trans.common.error.authErrorTitle,
      description,
      primaryButtonText: trans.common.close,
    });
  };

  const handleGoogleAuth = async () => {
    try {
      await signInWithGoogle();
      router.replace(`${searchParams.get("continueUrl") ?? "/dashboard"}`);
    } catch (e: any) {
      showErrorMessage(e.code);
    }
  };

  const handleGithubAuth = async () => {
    try {
      const user = await signInWithGithub();
      router.replace(`${searchParams.get("continueUrl") ?? "/dashboard"}`);
    } catch (e: any) {
      showErrorMessage(e.code);
    }
  };
  const handleTwitterAuth = async () => {
    try {
      const user = await signInWithTwitter();
      router.replace(`${searchParams.get("continueUrl") ?? "/dashboard"}`);
    } catch (e: any) {
      showErrorMessage(e.code);
    }
  };

  const handleFacebookAuth = async () => {
    try {
      const user = await signInWithFacebook();
      router.replace(`${searchParams.get("continueUrl") ?? "/dashboard"}`);
    } catch (e: any) {
      showErrorMessage(e.code);
    }
  };

  const handleYahooAuth = async () => {
    try {
      const user = await signInWithYahoo();
      router.replace(`${searchParams.get("continueUrl") ?? "/dashboard"}`);
    } catch (e: any) {
      showErrorMessage(e.code);
    }
  };

  return (
    <div className="flex justify-center flex-wrap">
      <AuthButton
        identifier={AUTH_IDENTIFIER.GOOGLE}
        onAuthRequest={handleGoogleAuth}
      />

      <AuthButton
        identifier={AUTH_IDENTIFIER.GITHUB}
        onAuthRequest={handleGithubAuth}
      />
      <AuthButton
        identifier={AUTH_IDENTIFIER.X}
        onAuthRequest={handleTwitterAuth}
      />

      <AuthButton
        identifier={AUTH_IDENTIFIER.FACEBOOK}
        onAuthRequest={handleFacebookAuth}
      />

      <AuthButton
        identifier={AUTH_IDENTIFIER.YAHOO}
        onAuthRequest={handleYahooAuth}
      />
    </div>
  );
};

export default Social;
