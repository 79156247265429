export const REGEX = {
  PASSWORD: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,128}$/,
  NAME: /^[a-zA-Z0-9 -@]{2,128}$/,
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,128})+$/,
};

export const AUTH_IDENTIFIER = {
  EMAIL: "Email",
  GOOGLE: "Google",
  APPLE: "Apple",
  GITHUB: "Github",
  X: "X",
  MICROSOFT: "Microsoft",
  FACEBOOK: "Facebook",
  YAHOO: "Yahoo",
};
